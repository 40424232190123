<template>
	<sz-content-card class="note-board" :title="$t(`comp.noteBoard.title`, entity)">
		<template slot="toolbar-actions">
			<v-btn color="primary" ml-2 @click="edit()">{{ $t(`comp.noteBoard.newNote`) }}</v-btn>
		</template>

		<div class="filter">
			<v-checkbox v-model="showUnpublishedNotes" :label="$t('comp.noteBoard.filter.showUnpublished')" />
			<v-checkbox v-model="showActiveNotes" :label="$t('comp.noteBoard.filter.showActive')" />
			<v-checkbox v-model="showScheduledNotes" :label="$t('comp.noteBoard.filter.showScheduled')" />
			<v-checkbox v-model="showExpiredNotes" :label="$t('comp.noteBoard.filter.showExpired')" />
			<v-checkbox v-model="showInheritedNotes" :label="$t('comp.noteBoard.filter.showInherited')" />
		</div>

		<div class="notes" v-if="sortedNotes && sortedNotes.length > 0">
			<div v-if="stickyNotes.length > 0">
				<note-board-note
					v-for="(note, index) in stickyNotes"
					:key="index"
					:entity="entity"
					:note="note"
					:index="index"
					:list-length="stickyNotes.length"
					:edit="edit"
				/>
			</div>

			<note-board-note
				v-for="(note, index) in regularNotes"
				:key="index"
				:entity="entity"
				:note="note"
				:index="index"
				:list-length="regularNotes.length"
				:edit="edit"
			/>
		</div>

		<sz-loading-state v-else-if="loading" />

		<sz-empty-state v-else :label="$t(`comp.noteBoard.empty.label`)" icon="note">
			<p>{{ $t(`comp.noteBoard.empty.description1`) }}</p>
			<p>{{ $t(`comp.noteBoard.empty.description2`) }}</p>

			<template slot="actions">
				<v-btn color="primary" @click="edit()">{{ $t(`comp.noteBoard.empty.createFirstNote`) }}</v-btn>
			</template>
		</sz-empty-state>

		<edit-note-dialog v-model="showEditDialog" :note="editNote" />
	</sz-content-card>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { Note } from "@/models/Note";

import EditNoteDialog from "./EditNoteDialog.vue";
import NoteBoardNote from "./Note.vue";

@Component({
	components: { EditNoteDialog, NoteBoardNote },
})
export default class NoteBoard extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	now = new Date();

	showInheritedNotes: boolean = true;
	showUnpublishedNotes: boolean = true;
	showActiveNotes: boolean = true;
	showScheduledNotes: boolean = true;
	showExpiredNotes: boolean = false;

	loading = true;
	showEditDialog: boolean = false;
	editNote: Note = new Note();

	get notes(): Note[] {
		return this.$store.getters.notesFor(this.entity);
	}

	get filterdNotes() {
		return this.notes.filter((note) => {
			const isInherited = note.entityId !== this.entity.id;
			if (!this.showInheritedNotes && isInherited) return false;

			const isUnpublished = note.state !== "Published";
			if (!this.showUnpublishedNotes && isUnpublished) return false;

			const isActive = note.start < this.now && (note.end > this.now || !note.end);
			if (this.showActiveNotes && isActive) return true;

			const isScheduled = note.start > this.now;
			if (this.showScheduledNotes && isScheduled) return true;

			const isExpired = note.end && note.end < this.now;
			if (this.showExpiredNotes && isExpired) return true;

			return false;
		});
	}

	get sortedNotes() {
		return this.filterdNotes.sort((a, b) => {
			const av = new Date(a.start).valueOf();
			const bv = new Date(b.start).valueOf();

			if (av > bv) return -1;
			if (av < bv) return 1;

			if (av === bv) {
				if (a.id > b.id) return -1;
				if (a.id < b.id) return 1;
			}

			return 0;
		});
	}

	get regularNotes() {
		return this.sortedNotes.filter((n) => !n.sticky);
	}

	get stickyNotes() {
		return this.sortedNotes.filter((n) => !!n.sticky);
	}

	// get noteBoardBgColor() {
	// 	return this.entity.noteBoardBackgroundColor || "#e6e6e6";
	// }

	@Watch("entity", { immediate: true })
	async onEntityChange() {
		this.loading = true;
		await this.$store.dispatch("fetchNotes", { entity: this.entity });
		this.loading = false;
	}

	edit(note?: Note) {
		const start = new Date();

		start.setHours(0, 0, 0, 0);

		this.editNote =
			note ||
			new Note({
				start,
				entityId: this.entity.id,
			});
		this.showEditDialog = true;
	}
}
</script>

<style lang="scss">
.note-board {
	.filter {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		background: rgba(0, 0, 0, 0.25);
		padding: 16px;

		> .v-input {
			margin: 0;

			.v-input__control {
				.v-messages {
					display: none;
				}
			}
		}
	}

	.notes {
		background: rgba(0, 0, 0, 0.04);
		// background-color: #e6e6e6;
	}
}
</style>
