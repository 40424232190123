<template>
	<div id="authentication" :class="$route.name">
		<div class="wrapper elevation-24">
			<div class="logo-banner">
				<img src="@/assets/styretavla-logo-white-green.svg" />
			</div>

			<div class="login" v-if="isLogin">
				<login-form />

				<div class="information">
					<p v-html="$t('route.authentication.login.infoText', [supportEmail])" />
				</div>
			</div>

			<div class="logout" v-if="isLogout">
				<div class="headline">{{ $t("route.authentication.logout.title") }}</div>

				<div class="information">
					<p>{{ $t("route.authentication.logout.infoText") }}</p>
					<p>
						<router-link :to="{ name: 'auth--login' }">{{ $t("route.authentication.logout.logBackIn") }}</router-link>
					</p>
				</div>
			</div>

			<div class="reset-password" v-if="isResetPassword">
				<form
					class="authentication-form reset-password-form"
					@submit.prevent="resetPassword"
					v-if="!resetPasswordRequestSent"
				>
					<div class="headline">{{ $t("route.authentication.resetPassword.title") }}</div>

					<v-text-field
						v-model="resetPasswordEmail"
						:disabled="resetPasswordRequestSending"
						:label="$t('generic.email')"
					/>

					<v-layout align-center justify-space-between>
						<router-link :to="{ name: 'auth--login' }">{{
							$t("route.authentication.resetPassword.backToLogin")
						}}</router-link>
						<v-btn text type="submit" :disabled="resetPasswordRequestSending">{{
							$t("route.authentication.resetPassword.submit")
						}}</v-btn>
					</v-layout>
				</form>

				<div class="information" v-if="!resetPasswordRequestSent">
					<p>{{ $t("route.authentication.resetPassword.infoText") }}</p>
					<p v-html="$t('route.authentication.resetPassword.stillHavingTrouble', [supportEmail])" />
				</div>

				<div class="information" v-else>
					<p>{{ $t("route.authentication.resetPassword.requestSubmittedInfoText") }}</p>

					<p>
						<router-link :to="{ name: 'auth--login' }">{{
							$t("route.authentication.resetPassword.backToLogin")
						}}</router-link>
					</p>
				</div>
			</div>

			<div class="reset-password-confirm" v-if="isResetPasswordConfirm && hasResetPasswordConfirmToken">
				<form
					class="authentication-form reset-password-confirm-form"
					@submit.prevent="resetPasswordConfirm"
					v-if="!resetPasswordConfirmSent && !resetPasswordConfirmError"
				>
					<div class="headline">{{ $t("route.authentication.resetPasswordConfirm.title") }}</div>

					<v-text-field
						v-model="resetPasswordConfirmNewPassword"
						:disabled="resetPasswordConfirmSending"
						type="password"
						:label="$t('generic.newPassword')"
					/>

					<v-layout align-center justify-space-between>
						<router-link :to="{ name: 'auth--login' }">{{ $t("generic.actions.cancel") }}</router-link>
						<v-btn text type="submit" :disabled="resetPasswordConfirmSending">{{
							$t("route.authentication.resetPasswordConfirm.submit")
						}}</v-btn>
					</v-layout>
				</form>

				<div class="information" v-if="!resetPasswordConfirmSent && !resetPasswordConfirmError">
					<p>{{ $t("route.authentication.resetPasswordConfirm.infoText") }}</p>
					<p>{{ $t("route.authentication.resetPasswordConfirm.enterPasswordAbove", [supportEmail]) }}</p>
				</div>

				<div class="information error" v-else-if="resetPasswordConfirmError">
					<p class="headline">{{ $t("route.authentication.resetPasswordConfirm.error.headline") }}</p>
					<p>{{ $t("route.authentication.resetPasswordConfirm.error.infoText") }}</p>
					<p>
						<router-link :to="{ name: 'auth--reset-password' }">{{
							$t("route.authentication.resetPasswordConfirm.error.tryAgain")
						}}</router-link>
					</p>
					<p v-html="$t('route.authentication.resetPasswordConfirm.error.contactSupport', [supportEmail])" />
				</div>

				<div class="information" v-else>
					<p class="headline">{{ $t("route.authentication.resetPasswordConfirm.success.headline") }}</p>
					<p>{{ $t("route.authentication.resetPasswordConfirm.success.infoText") }}</p>
					<p>
						<router-link :to="{ name: 'auth--login' }">{{
							$t("route.authentication.resetPasswordConfirm.success.toLogin")
						}}</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { CONTENT_SUPPORT_EMAIL } from "@/constants";
import { loginRedirect } from "@/helpers";

import LoginForm from "@/routes/Global/LoginForm.vue";

@Component<Authentication>({
	components: { LoginForm },
})
export default class Authentication extends Vue {
	supportEmail: string | null = CONTENT_SUPPORT_EMAIL ?? null;

	resetPasswordRequestSending = false;
	resetPasswordRequestSent = false;
	resetPasswordEmail: string = "";

	@Prop()
	resetPasswordConfirmToken!: string;
	resetPasswordConfirmError = false;
	resetPasswordConfirmSending = false;
	resetPasswordConfirmSent = false;
	resetPasswordConfirmNewPassword: string = "";

	get isLogout() {
		return this.$route.name === "auth--logout";
	}

	get isLogin() {
		return this.$route.name === "auth--login";
	}

	get isResetPassword() {
		return this.$route.name === "auth--reset-password";
	}

	get isResetPasswordConfirm() {
		return this.$route.name === "auth--reset-password-confirm";
	}

	get hasResetPasswordConfirmToken() {
		return !!this.resetPasswordConfirmToken;
	}

	async mounted() {
		// If route is logout, set token to false.
		if (this.isLogout) {
			await this.$store.dispatch("logout");
		}

		// Do inital redirect check.
		await loginRedirect(this);
	}

	async resetPassword() {
		if (this.resetPasswordRequestSending) return;
		this.resetPasswordRequestSending = true;
		await this.$store.dispatch("resetPassword", { email: this.resetPasswordEmail });
		this.resetPasswordRequestSent = true;
	}

	async resetPasswordConfirm() {
		if (this.resetPasswordConfirmSending) return;
		this.resetPasswordConfirmSending = true;

		try {
			await this.$store.dispatch("resetPasswordConfirm", {
				token: this.resetPasswordConfirmToken,
				newPassword: this.resetPasswordConfirmNewPassword,
			});
		} catch (err) {
			this.resetPasswordConfirmError = true;
		}

		this.resetPasswordConfirmSent = true;
	}
}
</script>

<style lang="scss" scoped>
$login-column-width: 400px;
$login-column-content-padding: 16px;

#authentication {
	height: 100%;
	position: relative;
	background: #019939;

	.wrapper {
		background: rgba(255, 255, 255, 0.95);
		max-height: 0%;
		max-width: $login-column-width;
		overflow: auto;
		padding-top: 0vh;
		width: 100%;
		position: absolute;
		right: 50%;
		top: 50%;
		transform: translate(50%, -50%);
		transition: 0.3s ease all;

		.login,
		.logout,
		.reset-password,
		.reset-password-confirm {
			> div,
			form {
				padding: $login-column-content-padding;
			}
		}

		.logo-banner {
			background: rgba(54, 54, 54, 0.85);
			margin-bottom: 40px;
			padding: $login-column-content-padding;

			img {
				display: block;
				margin: 0 0 0 auto;
				max-width: 150px;
			}
		}
	}

	&.auth--login .wrapper {
		height: 100%;
		max-height: 100%;
		right: 50%;
		top: 0;
		transform: translate(50%, 0);

		@media screen and (min-height: 500px) {
			padding-top: 6vh;
		}
		@media screen and (min-height: 630px) {
			padding-top: 12vh;
		}

		@media screen and (min-width: 500px) {
			right: 10%;
			transform: translate(0, 0);
		}
		@media screen and (min-width: 900px) {
			right: 15%;
		}
		@media screen and (min-width: 1200px) {
			right: 20%;
		}
	}

	&.auth--logout .wrapper {
		max-height: 500px;
	}

	&.auth--reset-password .wrapper {
		max-height: 800px;
	}

	&.auth--reset-password-confirm .wrapper {
		max-height: 800px;
	}
}
</style>
