<template>
	<li class="item" :class="[entity.type.toLowerCase()]" @click="openChildren">
		<router-link :to="`/entities/${entity.id}`">
			<v-icon class="icon">{{ $scrinz.getIconForEntityType(entity.type) }}</v-icon>
			<span
				v-if="$scrinz.entityCanHaveChildren(entity) && !isOrganization"
				class="toggle"
				@click.prevent.stop="toggleChildren"
			>
				<v-icon class="toggle__icon" v-if="!showChildren">expand_more</v-icon>
				<v-icon class="toggle__icon" v-if="showChildren">expand_less</v-icon>
			</span>

			<span class="label">{{ entity.name }}</span>

			<span class="settings" @click.prevent="navigateToSettings">
				<v-icon class="settings__icon">settings</v-icon>
			</span>
		</router-link>

		<div v-if="$scrinz.entityCanHaveChildren(entity)" v-show="showChildren" class="children">
			<slot />
		</div>
	</li>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";

@Component({
	name: "s-entity-sidebar-list-item",
	components: {},
})
export default class SEntitySidebarListItem extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	hover: boolean | number = false;
	showChildren: boolean = true;

	isCurrentRoute() {
		return this.$route.params.id === this.entity.id.toString();
	}

	get isOrganization() {
		return this.entity.type === "Organization";
	}

	mounted() {
		// If is top level, or user isn't admin, display children.
		if (!this.entity.parentId || !this.$can("admin")) {
			this.showChildren = true;
		}
	}

	openChildren() {
		if (this.showChildren === false) this.showChildren = true;
	}

	toggleChildren() {
		if (this.entity.type !== "Organization") {
			this.showChildren = !this.showChildren;
		}
	}

	navigateToSettings() {
		this.$router.push({ name: "client--entity--settings", params: { id: this.entity.id.toString() } });
	}
}
</script>

<style lang="scss">
.item {
	& + &.group {
		margin-top: 0.5rem;
	}

	&.group {
		> a {
			background-color: #3d7b3b;

			&:hover,
			&.router-link-active {
				> .icon {
					display: none;
				}

				> .toggle {
					display: inline-block;
				}
			}
		}
	}

	> a {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		align-items: center;
		padding: 0.5rem 0.5rem 0.5rem 0.8rem;
		border-radius: 0.3rem;
		color: white !important;
		text-decoration: none;

		> .label {
			flex: 1;
		}

		> .toggle,
		> .settings {
			display: none;
			padding: 0.2rem;
			margin: -0.25rem -0.2rem;
			border-radius: 50%;

			&:hover {
				background: rgba(255, 255, 255, 0.3);
			}
		}

		&:hover,
		&.router-link-active {
			background-color: #40523b;

			> .settings {
				display: inline-block;
			}
		}

		&.router-link-active {
			font-weight: bold;
			background-color: #e0e0e0;
			color: black !important;
		}
	}

	.children {
		border-left: 0.1rem solid #333;
		margin: 0.5rem 0;
		padding-left: 0.6rem;
	}
}
</style>
