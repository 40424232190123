<template>
	<v-card :class="componentClass">
		<v-toolbar class="sz-content-card__toolbar white--text elevation-0" v-if="hasToolbarOrTitle">
			<slot name="toolbar">
				<span v-if="isCollapsible" class="sz-content-card__toolbar__collapse-toggle" @click="toggleCollapse">
					<v-icon>play_arrow</v-icon>
				</span>

				<span :class="titleClass">
					<slot name="title">{{ title }}</slot>
				</span>

				<v-spacer />

				<slot name="toolbar-actions" />
			</slot>
		</v-toolbar>

		<div class="sz-content-card__content">
			<slot>
				<v-card-text>
					<slot name="content" />
				</v-card-text>
			</slot>
		</div>

		<v-card-actions class="sz-content-card__actions grey lighten-4" v-if="!hideActions && hasActions">
			<slot name="actions">
				<slot name="actions-left" />
				<v-spacer />
				<slot name="actions-right" />
			</slot>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SzContentCard extends Vue {
	@Prop()
	title!: string;

	@Prop()
	titleExtraClass!: string;

	@Prop()
	hideActions!: boolean;

	@Prop({ default: false })
	collapsible!: boolean;

	@Prop({ default: false })
	collapsed!: boolean;

	isCollapsed = false;

	@Watch("collapsed", { immediate: true })
	onCollapsedChange() {
		this.isCollapsed = this.collapsed;
	}

	get isCollapsible() {
		return !!this.collapsible;
	}

	get componentClass() {
		return {
			"sz-content-card": true,
			"sz-content-card--collapsed": this.isCollapsed,
		};
	}

	get titleClass() {
		const cls: { [cls: string]: boolean } = {
			"sz-content-card__toolbar__title": true,
		};

		if (this.titleExtraClass) {
			cls[this.titleExtraClass] = true;
		}

		return cls;
	}

	get hasActions() {
		return !!this.$slots.actions || !!this.$slots["actions-left"] || !!this.$slots["actions-right"];
	}

	get hasTitle() {
		return !!this.$slots.title || !!this.title;
	}

	get hasToolbar() {
		return !!this.$slots.toolbar;
	}

	get hasToolbarOrTitle() {
		return this.hasToolbar || this.hasTitle;
	}

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
	}
}
</script>

<style lang="scss">
.sz-content-card {
	$card: &;

	+ .sz-content-card {
		margin: 24px 0 0;
	}

	> .v-toolbar > .v-toolbar__content {
		padding: 0 16px;
	}

	&__toolbar {
		background: #3e3f3e !important;

		&__collapse-toggle {
			cursor: pointer;
			margin-right: 0.4rem;
			transition: 0.3s ease;
			transform-origin: 50% 40%;
			transform: rotate(90deg);

			i {
				color: white !important;
			}

			&:hover i {
				color: black;
			}
		}

		&__title {
			display: flex;
			align-items: center;
			gap: 0.75rem;
			font-weight: bold;

			.title {
				font-weight: bold;
			}
		}
	}

	&__content {
		padding: 0;
	}

	&--collapsed {
		#{$card}__toolbar {
			&__collapse-toggle {
				transform: rotate(0deg);
			}
		}

		#{$card}__content,
		#{$card}__actions {
			display: none;
		}
	}
}
</style>
