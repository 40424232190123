<template>
	<div
		class="note-board-note"
		:class="{
			active: isActive,
			expired: isExpired,
			inherited: isInherited,
			scheduled: isScheduled,
			'newly-created': note.newlyCreated,
			'newly-updated': note.newlyUpdated,
		}"
	>
		<v-divider v-if="index !== 0" />

		<!-- <pre>{{ note }}</pre> -->

		<div class="header">
			<div class="meta">
				<span v-if="isUnpublished" class="state warning--text">
					{{ $t("comp.noteBoard.Unpublished") }}
				</span>

				<span v-else-if="isActive" class="state success--text">
					{{ $t("comp.noteBoard.Active") }}
				</span>

				<span v-else-if="isScheduled" class="state info--text">
					{{ $t("comp.noteBoard.Scheduled") }}
				</span>

				<span v-else-if="isExpired" class="state error--text">
					{{ $t("comp.noteBoard.Expired") }}
				</span>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<span v-on="on" class="date">
							{{ note.start | moment("D. MMMM") }}
						</span>
					</template>

					{{ note.start | moment("D. MMMM YYYY") }}
				</v-tooltip>

				<span v-if="showEndDate">&nbsp;-&nbsp;</span>

				<v-tooltip top v-if="showEndDate">
					<template v-slot:activator="{ on }">
						<span v-on="on">
							{{ displayEndDate | moment("D. MMMM") }}
						</span>
					</template>

					{{ displayEndDate | moment("D. MMMM YYYY") }}
				</v-tooltip>

				<span class="statuses">
					<v-tooltip top v-if="note.sticky">
						<template v-slot:activator="{ on }">
							<span v-on="on" class="pinned">
								<v-icon>lock_outline</v-icon>
							</span>
						</template>

						{{ $t("comp.noteBoard.pinnedToTop") }}
					</v-tooltip>

					<span class="unapproved center-text" v-if="note.state === 'Draft'">
						<v-icon :color="'warning'">block</v-icon>
						<span>{{ $t("generic.state.draft") }}</span>
					</span>
					<span class="unapproved center-text" v-else-if="note.state === 'PendingApproval'">
						<v-icon :color="'warning'">block</v-icon>
						<span>{{ $t("generic.state.pendingApproval") }}</span>
					</span>
					<span class="unapproved center-text" v-else-if="note.state === 'Rejected'">
						<v-icon :color="'error'">block</v-icon>
						<span>{{ $t("generic.state.notApproved") }}</span>
					</span>
					<span class="approved center-text" v-else-if="note.state === 'Published'">
						<v-icon :color="'success'">done</v-icon>
						<span>{{ $t("generic.state.published") }}</span>
					</span>
				</span>
			</div>

			<div class="actions" v-if="!isInherited">
				<v-menu bottom left>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
							<v-icon>menu</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-list-item @click="edit(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.editNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>edit</v-icon>
							</v-list-item-avatar>
						</v-list-item>

						<v-list-item v-if="isUnpublished" @click="toggleApproval(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.approveNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>done</v-icon>
							</v-list-item-avatar>
						</v-list-item>

						<v-list-item v-else @click="toggleApproval(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.rejectNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>block</v-icon>
							</v-list-item-avatar>
						</v-list-item>

						<v-divider />

						<v-list-item @click="deleteNote(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.deleteNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>delete_forever</v-icon>
							</v-list-item-avatar>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>

			<span class="inherited-info" v-else>
				{{ $t("generic.inheritedFrom") }}
				<router-link :to="`/entities/${note.entityId}/notes`">{{
					$store.getters.getEntity(note.entityId).name
				}}</router-link>
			</span>
		</div>

		<div
			class="content"
			:style="{
				background: `${this.entity.noteBoardBackgroundColor}aa`,
			}"
		>
			<sd-note
				ref="notes"
				class="note-board-notes__note"
				:date="note.start"
				:icon="note.icon"
				:icon-background-color="note.iconColor"
				:html-body="note.text"
				:qr-content="note.qrContent"
			/>
		</div>

		<v-divider v-if="index !== listLength - 1" />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { EditFunction } from "./interfaces";
import { SdNote } from "@scrinz/components";

@Component({
	components: {
		SdNote,
	},
})
export default class NoteBoardNote extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true })
	note!: any;

	@Prop({ required: true, type: [Function] })
	edit!: EditFunction;

	@Prop({ default: 0 })
	index!: number;

	@Prop({ default: 0 })
	listLength!: number;

	now = new Date();

	get start(): Date {
		return this.note.start;
	}

	get end(): Date {
		return this.note.end;
	}

	get isUnpublished(): boolean {
		return this.note.state !== "Published";
	}

	get isActive(): boolean {
		return this.note.start < this.now && (this.note.end > this.now || !this.note.end);
	}

	get isScheduled(): boolean {
		return this.note.start > this.now;
	}

	get isExpired(): boolean {
		return this.note.end && this.note.end < this.now;
	}

	get isInherited() {
		return this.note.entityId !== this.entity.id;
	}

	get showEndDate() {
		if (!this.end) return false;

		const test = new Date(this.end);

		test.setDate(this.end.getDate() - 1);

		if (
			this.start.getFullYear() === test.getFullYear() &&
			this.start.getMonth() === test.getMonth() &&
			this.start.getDate() === test.getDate() &&
			test.getHours() === 0 &&
			test.getMinutes() === 0
		) {
			return false;
		}

		return this.end;
	}

	get displayEndDate() {
		const end = this.end;

		if (end) {
			if (end.getHours() === 0 && end.getMinutes() === 0) {
				end.setDate(end.getDate() - 1);
			}
		}

		return end;
	}

	async toggleApproval(note: any) {
		note.state = note.state === "Published" ? "Rejected" : "Published";
		await this.$store.dispatch("updateNote", note);
	}

	async deleteNote(note: any) {
		const confirmed = await this.$confirm(this.$t(`comp.noteBoard.confirmDelete.text`), {
			color: "error",
			title: this.$t(`comp.noteBoard.confirmDelete.title`),
		});

		if (!confirmed) return;

		await this.$store.dispatch("deleteNote", note);
	}
}
</script>

<style lang="scss" scoped>
.note-board-note {
	// background: whitesmoke;
	// box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
	// box-shadow: 0 -3px 5px -2px #1d1a3733;

	.v-divider {
		// border-top-color: rgba(0, 0, 0, 0.05);
		// border-top-width: 8px;
		// border-bottom: none;
		// border-bottom-color: rgba(0, 0, 0, 0.05);
		// border-bottom-width: 8px;
		// border-top: none;

		border: none;
		margin: 0 0 12px;

		&:first-child {
			margin: 0;
		}
	}

	&.expired,
	&.inherited {
		color: grey;
		font-style: italic;
	}

	&.expired {
		filter: grayscale(0.5);
		opacity: 0.5;
	}

	&.inherited {
		background: rgba(112, 194, 194, 0.534);
		opacity: 0.8;
	}

	.header {
		background: rgba(0, 0, 0, 0.01);
		background: rgba(184, 168, 150, 0.3);
		// background: rgba(23, 178, 93, 0.05);
		border-bottom: 1px solid rgba(0, 0, 0, 0.043);
		display: flex;
		align-items: center;
		min-height: 56px;
		padding: 8px 16px;

		.meta {
			display: flex;
			align-items: center;
			flex-grow: 1;

			.icon {
				margin-right: 0.8rem;
				// flex-basis: 4%;
				width: 28px;

				&.small {
					font-size: 18px;
					opacity: 0.6;
				}
			}

			.state {
				font-size: 1.1rem;
				font-weight: bold;
				margin-right: 0.8rem;
				text-transform: uppercase;
			}

			.statuses {
				flex-grow: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.center-text {
					display: flex;
					align-items: center;

					> *:last-child {
						margin: 0 0.33em;
					}
				}
			}
		}

		.actions,
		.inherited-info {
			margin-left: 2.5%;
			min-width: 10%;
			text-align: right;
		}
	}

	.content {
		padding: 28px 42px;
		overflow: auto;
	}
}
</style>
