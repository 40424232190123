<template>
	<div class="s-layout-sidenav" :class="classes">
		<div class="s-layout-sidenav__leftnav">
			<div class="s-layout-sidenav__leftnav__wrapper primary elevation-4" :class="{ open: leftNavOpen }">
				<div class="s-layout-sidenav__leftnav__top">
					<router-link class="app-logo" :to="`/entities/${entityId}`">
						<styretavla-logo primary-color="white" secondary-color="black" />
					</router-link>
				</div>

				<div class="s-layout-sidenav__leftnav__menu">
					<s-entity-sidebar-organization-header />
					<s-entity-sidebar-list class="entity-sidebar-list" />
				</div>

				<div class="s-layout-sidenav__leftnav__bottom">
					<s-user-menu />
				</div>
			</div>
		</div>

		<main class="s-layout-sidenav__main">
			<app-toolbar @toggle-left-nav="toggleLeftNav" @toggle-main-nav="toggleMainNav">
				<slot name="breadcrumbs" />
			</app-toolbar>

			<div class="s-layout-sidenav__main__wrapper">
				<div class="s-layout-sidenav__main__nav" :class="{ open: mainNavOpen }">
					<sz-content-card class="s-layout-sidenav__main__nav__card" title-extra-class="title">
						<!--
							<template slot="title" v-if="hasSidenavTitle && $vuetify.breakpoint.mdAndUp">
								<slot name="sidenav-title">{{ sidenavTitle }}</slot>
							</template>

							<portal to="app-drawer-sidenav-title" v-else>
								<v-toolbar class="elevation-0">
									<v-toolbar-title>
										<slot name="sidenav-title">{{ sidenavTitle }}</slot>
									</v-toolbar-title>
								</v-toolbar>
							</portal>
						-->

						<portal to="app-drawer-sidenav" :disabled="true || $vuetify.breakpoint.mdAndUp">
							<v-list class="sidenav-list">
								<template v-for="(item, index) of sidenavList">
									<template v-if="shouldShow(item)">
										<v-divider :key="index" v-if="isDivider(item)" />

										<v-list-item v-else :key="index" :to="item.to">
											<v-list-item-avatar v-if="item.icon || sidenavForceIconSlot">
												<v-icon v-if="item.icon">{{ item.icon }}</v-icon>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title v-text="item.label" />
											</v-list-item-content>
										</v-list-item>
									</template>
								</template>
							</v-list>
						</portal>
					</sz-content-card>
				</div>

				<div class="s-layout-sidenav__main__content">
					<slot name="content" />
				</div>
			</div>
		</main>

		<div class="s-layout-sidenav__backdrop" :class="{ open: leftNavOpen || mainNavOpen }" @click="closeNavs" />
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import store from "@/store";

import AppToolbar from "@/components/AppToolbar.vue";
import SUserMenu from "@/components/UserMenu.vue";
import StyretavlaLogo from "@/components/StyretavlaLogo.vue";
import SEntitySidebarList from "@/components/Entity/Sidebar/List.vue";
import SEntitySidebarOrganizationHeader from "@/components/Entity/Sidebar/OrganizationHeader.vue";

@Component({
	components: { AppToolbar, SUserMenu, StyretavlaLogo, SEntitySidebarList, SEntitySidebarOrganizationHeader },
})
export default class LayoutSidenav extends Vue {
	@Prop()
	entity!: EntityInterface;

	@Prop({ default: true })
	breadcrumbs!: boolean;

	@Prop({ default: [] })
	sidenavList!: any[];

	@Prop()
	sidenavTitle!: string;

	@Prop({ type: [Boolean] })
	sidenavForceIconSlot!: boolean;

	leftNavOpen: boolean = false;
	mainNavOpen: boolean = false;

	get classes() {
		return {
			"s-layout-sidenav": true,
			"s-layout-sidenav--desktop": this.$vuetify.breakpoint.mdAndUp,
			"s-layout-sidenav--mobile": this.$vuetify.breakpoint.smAndDown,
		};
	}

	get hasSidenavTitle() {
		return !!this.$slots["sidenav-title"] || this.sidenavTitle;
	}

	isDivider(item: any) {
		return item === "divider" || item.type === "divider";
	}

	shouldShow(item: any) {
		if (typeof item === "string") {
			return item === "divider";
		}

		if ("can" in item) {
			return this.$can(item.can);
		}

		return true;
	}

	closeNavs() {
		this.closeLeftNav();
		this.closeMainNav();
	}

	closeLeftNav() {
		this.leftNavOpen = false;
	}

	toggleLeftNav() {
		this.leftNavOpen = !this.leftNavOpen;
	}

	closeMainNav() {
		this.mainNavOpen = false;
	}

	toggleMainNav() {
		this.mainNavOpen = !this.mainNavOpen;
	}

	get entityId() {
		if (!this.entity) {
			return store.getters.entityContext.id;
		}

		return this.entity.id;
	}

	@Watch("$route")
	onRouteChange() {
		this.closeNavs();
	}
}
</script>

<style lang="scss" scoped>
.s-layout-sidenav {
	$ls: &;
	height: 100%;
	position: relative;
	display: flex;

	&__leftnav {
		pointer-events: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;

		&__wrapper {
			pointer-events: all;
			position: absolute;
			z-index: 100;
			top: 0;
			left: 0;
			bottom: 0;
			width: 87%;
			max-width: 30rem;
			display: flex;
			flex-direction: column;
			transform: translateX(-100%);
			transition: transform 0.2s ease-in-out;

			&.open {
				transform: translateX(0);
			}
		}

		&__top {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem;
		}

		&__menu {
			flex: 1;
			overflow: auto;
			// border: 0 solid rgba(0, 0, 0, 0.4);
			border-width: 0.105rem 0;
			padding: 1rem;
		}

		&__bottom {
			padding: 1rem;
		}
	}

	&__main {
		flex: 1;
		padding: 1rem;
		overflow: auto;

		&__wrapper {
			display: flex;
		}

		.app-toolbar {
			margin: -1rem -1rem 1rem;
		}

		&__nav {
			pointer-events: none;
			position: fixed;
			top: 4rem;
			right: 1rem;
			z-index: 100;
			min-width: 15rem;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;

			&__card {
				border-radius: 4px;
				overflow: auto;
			}

			&-toggle {
				float: right;
			}

			&.open {
				opacity: 1;
				pointer-events: all;
			}
		}

		&__content {
			flex: 1;
		}
	}

	&__backdrop {
		pointer-events: none;
		cursor: pointer;
		position: absolute;
		z-index: 99;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.open {
			opacity: 1;
			pointer-events: all;
		}
	}

	@media screen and (min-width: 960px) {
		&__main__nav {
			pointer-events: all;
			position: relative;
			z-index: unset;
			top: unset;
			right: unset;
			width: 25%;
			margin-right: 1rem;
			opacity: 1;

			&-toggle {
				display: none;
			}
		}
	}

	@media screen and (min-width: 1264px) {
		.app-toolbar {
			margin: -0.5rem -0.5rem 0.5rem;
		}

		&__leftnav {
			position: relative;
			width: 25%;
			max-width: 20rem;

			&__wrapper {
				width: 100%;
				transform: translateX(0);
			}
		}
	}
}
</style>
