<template>
	<v-card :class="classes" @mouseover="onMouseOver" @mouseout="onMouseOut">
		<slot-popup :asset="asset" :imageUrl="imageUrl" :template="template" :open="isHover" />

		<slot-button v-if="isEditable" @click.native="$listeners.edit(template)" />

		<!--
			<div class="starts-before-indicator" v-if="startsBeforeView">
				<div class="cropped-line"></div>
				<div class="cropped-line"></div>
			</div>
		-->

		<slot-content
			:asset="asset"
			:imageUrl="imageUrl"
			:template="template"
			:is-hover="isHover"
			:is-editable="isEditable"
			:is-locked="isLocked"
			:is-inherited="isInherited"
		/>
	</v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { AssetObject, AssetTypeEnum, EntityInterface } from "@scrinz/dtos";
import { encodeHashid } from "@scrinz/hashids";
import { API_URL } from "@/constants";

import SlotButton from "./Slot/Button.vue";
import SlotContent from "./Slot/Content.vue";
import SlotPopup from "./Slot/Popup.vue";

import { getTemplateById } from "@/assets/templates";

@Component({
	components: { SlotButton, SlotContent, SlotPopup },
})
export default class TemplatesEditorSlot extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true })
	template!: any;

	@Prop({ default: false })
	isLocked!: boolean;

	@Prop({ default: false })
	startsBeforeView!: boolean;

	imageUrl = "";
	isHover = false;

	get isEditable() {
		return !this.isLocked && !this.isInherited;
	}

	get isInherited() {
		return this.entity.id !== this.template.entityId;
	}

	get classes() {
		return {
			"templates-editor__slot": true,
			"templates-editor__slot--editable": this.isEditable,
			"templates-editor__slot--inherited": this.isInherited,
			"templates-editor__slot--locked": this.isLocked,
			"templates-editor__slot--starts-before-view": this.startsBeforeView,
		};
	}

	get asset(): AssetObject {
		return this.$store.getters.getAsset(this.template.asset?.id);
	}

	get inheritedFrom() {
		return this.$store.getters.getEntity(this.template.entityId);
	}

	onMouseOver(_e: MouseEvent) {
		// console.log(e);
		this.isHover = true;
	}

	onMouseOut(_e: MouseEvent) {
		this.isHover = false;
	}

	// @Watch("asset", { deep: true, immediate: true })
	@Watch("template", { deep: true, immediate: true })
	onTemplateChange() {
		if (!this.template) return;

		if (this.template.type === "AssetBanner") {
			if (!this.asset) return;

			const version = this.asset.hasCropped ? "cropped" : "original";
			const url = API_URL;
			const path = `/assets/${encodeHashid("asset", this.asset.id)}/${version}`;
			const cache = `?cache=${new Date().getTime()}`;

			this.imageUrl = `${url}${path}${cache}`;
		} else if (this.template.type === "TemplateBanner") {
			const template = getTemplateById(this.template.templateId);
			if (template) {
				this.imageUrl = template.image;
			}
		}
	}
}
</script>

<style lang="scss">
.templates-editor__slot {
	$slot: &;

	position: relative;

	&.theme--light.v-card {
		background: rgba(184, 168, 150, 1);
	}

	&--locked,
	&--inherited {
		&.theme--light.v-card {
			background: lightgrey;

			// .slot-content:after {
			// 	background: linear-gradient(
			// 		to bottom,
			// 		rgba(0, 0, 0, 0) 0%,
			// 		lightgrey 100%
			// 	);
			// }
		}
	}

	&--starts-before-view {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	& &__button {
		margin: 0;
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	// .slot-content {
	// 	&:after {
	// 		background: linear-gradient(
	// 			to bottom,
	// 			rgba(0, 0, 0, 0) 0%,
	// 			rgba(184, 168, 150, 1) 100%
	// 		);
	// 		content: "";
	// 		position: absolute;
	// 		right: 0;
	// 		bottom: 0;
	// 		width: 100%;
	// 		height: 20px;
	// 		max-width: 120px;
	// 	}
	// }

	// .starts-before-indicator {
	// 	position: absolute;
	// 	left: 10px;
	// 	right: 10px;
	// 	// top: -2px;
	// 	height: 5px;

	// 	.cropped-line {
	// 		background: linear-gradient(
	// 			-45deg,
	// 			transparent,
	// 			transparent 49%,
	// 			black 49%,
	// 			transparent 51%
	// 		);
	// 		background-size: 10px 10px;
	// 		position: absolute;
	// 		height: 100%;
	// 		left: 0;
	// 		right: 0;
	// 	}

	// 	.cropped-line:nth-child(1n + 2) {
	// 		background-image: linear-gradient(
	// 			45deg,
	// 			transparent,
	// 			transparent 49%,
	// 			black 49%,
	// 			transparent 51%
	// 		);
	// 	}
	// }
}
</style>
