<template>
	<v-app>
		<!-- <development-toolbar /> -->
		<context-switcher v-if="hasSession && $can('admin')" />
		<!--
			<app-toolbar v-if="hasSession" />
		-->

		<router-view />
	</v-app>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import ContextSwitcher from "@/components/ContextSwitcher.vue";
// import DevelopmentToolbar from "@/components/DevelopmentToolbar.vue";

@Component({
	components: {
		ContextSwitcher,
		// DevelopmentToolbar,
	},
})
export default class App extends Vue {
	@Getter hasSession!: boolean;
}
</script>

<style lang="scss">
html {
	overflow: hidden;
}

#app {
	background-color: #eae1e1;
	font-family: "Raleway", Verdana, Geneva, Tahoma, sans-serif;
	font-weight: 500;
	height: 100vh;
	overflow: hidden;

	.v-application--wrap {
		overflow: auto;
	}

	.v-card__text {
		font-weight: 500;
	}
}
</style>
