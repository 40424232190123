<template>
	<div class="app-toolbar elevation-3">
		<v-btn text large class="left-nav-toggle" @click="toggleLeftNav"
			><v-icon>tv</v-icon> {{ $t("generic.displays") }}</v-btn
		>
		<v-btn icon large class="main-nav-toggle" @click="toggleMainNav"><v-icon>menu</v-icon></v-btn>

		<div class="app-toolbar__content">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppToolbar extends Vue {
	toggleLeftNav() {
		this.$emit("toggle-left-nav");
	}

	toggleMainNav() {
		this.$emit("toggle-main-nav");
	}
}
</script>

<style lang="scss" scoped>
.app-toolbar {
	background: #019939;
	// margin: -1rem -1rem 1rem;
	padding: 0.5rem;
	overflow: auto;

	*:not(button),
	& ::v-deep a,
	& ::v-deep .v-icon {
		color: white !important;
	}

	.left-nav-toggle {
		// margin-right: 0.5rem;

		&.v-btn {
			padding: 0.5rem;

			& ::v-deep .v-btn__content {
				color: white;
			}
		}

		.v-icon {
			margin-right: 0.5rem;
		}
	}

	.main-nav-toggle {
		float: right;
		// margin: 0 0 0 0.5rem;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.5rem;
		margin: 0 0 0.5rem;
	}

	@media screen and (min-width: 960px) {
		.main-nav-toggle {
			display: none;
		}
	}

	@media screen and (min-width: 1264px) {
		background: transparent; // FIXME: important
		box-shadow: none !important; // FIXME: important
		padding-bottom: 0;
		// padding: 0;
		// margin: 0 0 1rem;

		*:not(button),
		& ::v-deep a,
		& ::v-deep .v-icon {
			color: #333 !important;
		}

		.left-nav-toggle {
			display: none;
		}
	}
}
</style>
