<template>
	<ol class="s-entity-sidebar-list list">
		<s-entity-sidebar-list-item v-for="entity of sortedEntities" :key="entity.id" :entity="entity">
			<s-entity-sidebar-list
				v-if="$scrinz.entityCanHaveChildren(entity) && hasChildren(entity)"
				:class="entity.type"
				:entity="entity"
				:parent="entity.id"
			/>

			<!-- <div class="s-entity-sidebar-list__children__empty" v-else>{{ $t("Group empty") }}</div> -->
		</s-entity-sidebar-list-item>
	</ol>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { sortEntitiesByNaturalName, sortEntitiesByType } from "@scrinz/helpers";

import store from "@/store";
import SEntitySidebarListItem from "./ListItem.vue";

@Component({
	name: "s-entity-sidebar-list",
	components: { SEntitySidebarListItem },
})
export default class SEntitySidebarList extends Vue {
	@Prop()
	entity!: EntityInterface;

	@Prop({ type: [String, Number] })
	parent!: number;

	@Prop({ default: "name" })
	sortBy!: string;

	@Prop({ default: "ASC" })
	sortDirection!: string;

	get entities() {
		if (!this.entity) {
			return store.getters.getChildrenOfEntity(store.getters.entityContext);
		}

		return store.getters.getChildrenOfEntity(this.entity);
	}

	get sortedEntities() {
		let entities: EntityInterface[] = this.entities;

		switch (this.sortBy) {
			case "name":
				entities = sortEntitiesByNaturalName(entities);
				break;
			case "type":
				entities = sortEntitiesByType(entities);
				break;
		}

		return this.sortDirection.toUpperCase() === "DESC" ? entities.reverse() : entities;
	}

	hasChildren(entity: EntityInterface) {
		return store.getters.getChildrenOfEntity(entity).length > 0;
	}
}
</script>

<style lang="scss">
.s-entity-sidebar-list.list {
	border-radius: 4px;
	margin: 0;
	padding: 0;
	list-style: none;

	&__children {
		&__empty {
			color: grey;
		}

		&.hover {
			background: lightcoral;
		}
	}
}
</style>
