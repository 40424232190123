<template>
	<div class="client-entity-details-templates">
		<templates-editor :entity="entity" />
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import TemplatesEditor from "@/components/TemplatesEditor";

@Component<ClientEntityDetailsTemplates>({
	title: (vm) => ["generic.templates", vm.entity.name],
	components: { TemplatesEditor },
})
export default class ClientEntityDetailsTemplates extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}
}
</script>
